import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import { extractDate, randomID } from './util'
import * as api from './api'
import { AdminLevel } from './data'

Vue.use(Vuex)

function grab(state, key, endpoint, force = false, params = {}) {
  return new Promise((resolve) => {
    // arrays become objects when they become Observers
    if (!force && Object.keys(state[key]).length !== 0) {
      resolve(state[key])
    } else {
      api.get(endpoint, params)
        .then(json => {
          state[key] = json
          resolve(state[key])
        })
    }
  })
}

function entryEndpoint(entry) {
  return `/entry/${entry.type}`
}

function entryPayload(entry) {
  const { type, uid, start, end, notes } = entry

  if (type === 'workshop') {
    const { job } = entry
    const job_id = job?.id || 0
    return { uid, start, end, job_id, notes }
  } else if (type === 'shoot') {
    const {
      target, job, as_role, depart_home, start_at_base, call, lunch,
      lunch_hours, wrap, depart, finish_at_base, arrive_home,
      outward_mode, return_mode, outward_vehicle, return_vehicle, mileage
    } = entry
    const job_id = job.id
    return {
      target, uid, start, end, job_id, notes, as_role,
      depart_home, start_at_base, call, lunch, lunch_hours,
      wrap, depart, finish_at_base, arrive_home,
      outward_mode, return_mode, outward_vehicle, return_vehicle, mileage
    }
  } else if (type === 'leave') {
    const { reason, all_day } = entry
    return { uid, start, end, reason, all_day, notes }
  }
}

export default new Vuex.Store({
  state: {
    entries: {},
    userNames: [],
    users: [],
    user: Cookies.getJSON('user'),
    jobs: [],
    jobColours: {},
    holidayDays: {},
    weekCache: {
      start: null,
      end: null,
      entries: []
    },
    dayJobCache: {},
    entryWeekCache: {
      start: '9999-12-31',
      end: '0000-01-01'
    },
    dayTotalHours: {},
    userPreferences: {
      autofillDayJob: false
    },
    keysDown: {}
  },
  getters: {
    entriesList: (state) => {
      return Object.values(state.entries)
    },
    entrySynced: (state) => (entry) => {
      const e = state.entries[entry.uid]
      return e && e.notSaved === undefined
    },
    userForID: (state) => (id) => {
      for (const user of state.users) {
        if (user.id === id) {
          return user
        }
      }
      return null
    },
    jobForID: (state) => (id) => {
      for (const job of state.jobs) {
        if (job.id === id) {
          return job
        }
      }
      return null
    },
    dayHasLunch: (state) => (start) => {
      const date = extractDate(start)
      for (const entry of Object.values(state.entries)) {
        if (entry.type === 'workshop' && date === extractDate(entry.start) && !entry.job) {
          return true
        }
      }
      return false
    },
    userFromSlug: (state) => (slug) => {
      for (const user of state.users) {
        if (user.slug === slug) {
          return user
        }
      }
      return { slug: 'unknown' }
    },
    credentials: (state) => {
      const { user } = state
      if (user) {
        return [user.id, user.api_key]
      }
      return ['', '']
    },
    jobsList: (state) => {
      return state.jobs.map((job) => {
        const { number, name } = job
        return Object.assign({}, job, {
          value: job.id,
          text: `${number} ${name}`
        })
      })
    },
    entryClashes: (state) => (entry) => {
      for (const existing of state.weekCache.entries) {
        if (entry.uid === existing.uid) continue
        if ((entry.end > existing.start && entry.end < existing.end) ||
          (entry.start < existing.end && entry.start > existing.start)) {
          return true
        }
      }
      return false
    },
    jobForDay: (state) => (timestamp) => {
      return state.dayJobCache[extractDate(timestamp)]?.id || null
    },
    userInOffice: (state) => {
      return state.user.admin >= AdminLevel.OFFICE
    },
    userInFinance: (state) => {
      return state.user.admin >= AdminLevel.FINANCE
    },
    userIsSuper: (state) => {
      return state.user.admin >= AdminLevel.SUPER
    },
    userPreferenceKey: (state) => {
      return `user-${state.user.id}-preferences`
    },
    keyIsPressed: (state) => (key) => {
      return !!state.keysDown[key]
    }
  },
  mutations: {
    createEntry(state, entry) {
      const uid = randomID()
      Object.assign(entry, {
        uid,
        notes: '',
        as_role: null,
        notSaved: true
      })
      Vue.set(state.entries, uid, entry)
      this.commit('prepareWeekCache', { refresh: true })
    },
    updateEntry(state, entry) {
      Vue.set(state.entries, entry.uid, entry)
      this.commit('prepareWeekCache', { refresh: true })
      if (entry.job) {
        state.dayJobCache[extractDate(entry.start)] = entry.job
      }
    },
    deleteEntry(state, entry) {
      Vue.delete(state.entries, entry.uid)
      this.commit('prepareWeekCache', { refresh: true })
    },
    clearEntries(state) {
      Vue.set(state, 'entries', {})
      state.entryWeekCache = { start: '9999-12-31', end: '0000-01-01' }
    },
    prepareWeekCache(state, { startDate, endDate, refresh }) {
      // we might be called before the entries have been loaded, so take note of dates
      if (!refresh) {
        state.weekCache.start = startDate
        state.weekCache.end = endDate
      }
      const { start, end } = state.weekCache
      state.weekCache.entries = Object.values(state.entries)
        .filter((entry) => {
          return entry.start >= start && entry.start <= end
        })
    },
    login(state, user) {
      state.user = user
      Cookies.set('user', user, { expires: 1000 })
    },
    logout(state) {
      state.user = null
      Cookies.remove('user')
    }
  },
  actions: {
    getUserNames({ state }) {
      return grab(state, 'userNames', '/user/names')
    },
    getUsers({ state }, force = false) {
      return grab(state, 'users', '/user/data', force)
    },
    refreshUser({ state }) {
      return grab(state, 'user', '/user/details', true)
    },
    getJobs({ state }) {
      return grab(state, 'jobs', '/jobs')
        .then((jobs) => {
          state.jobs = jobs.map((job) => {
            const { number, name } = job
            return Object.assign({}, job, {
              value: job.id,
              text: `${number} ${name}`
            })
          })
        })
    },
    getJobColours({ state }) {
      return grab(state, 'jobColours', '/jobs/colours')
    },
    getHolidayDays({ state }) {
      return grab(state, 'holidayDays', '/holiday/days')
    },
    getUserEntries({ state, getters }, { userSlug, start, end }) {
      const endpoint = userSlug ? `/user/${userSlug}/entries` : '/user/entries'

      return new Promise((resolve) => {
        if (start < state.entryWeekCache.start || end > state.entryWeekCache.end) {
          return api.get(endpoint, { start, end }).then(json => {
            state.entries = { ...state.entries, ...json }
            if (start < state.entryWeekCache.start) {
              state.entryWeekCache.start = start
            }
            if (end > state.entryWeekCache.end) {
              state.entryWeekCache.end = end
            }

            state.dayTotalHours = {}
            for (const { start, end, job, lunch_hours } of Object.values(state.entries)) {
              if (job?.id) {
                const date = extractDate(start)
                let hours = (new Date(end) - new Date(start)) / 1000 / 60 / 60
                if (lunch_hours !== undefined) {
                  hours -= lunch_hours
                }
                if (date in state.dayTotalHours) {
                  state.dayTotalHours[date] += hours
                } else {
                  state.dayTotalHours[date] = hours
                }
              }
            }

            resolve(state.entries)
          })
        } else {
          resolve(state.entries)
        }
      })
    },
    addEntry({ state, commit }, entry) {
      Vue.delete(entry, 'notSaved')
      commit('updateEntry', entry)

      let endpoint = entryEndpoint(entry)
      if (entry.user.id !== state.user.id) {
        endpoint += `/${entry.user.id}`
      }

      return api.post(endpoint, entryPayload(entry))
    },
    updateEntry({ state, commit }, entry) {
      commit('updateEntry', entry)
      return api.put(entryEndpoint(entry), entryPayload(entry))
    },
    removeEntry({ state, commit }, entry) {
      commit('deleteEntry', entry)
      return api.del('/entry', { entry_type: entry.type, uid: entry.uid })
    },
    loadUserPreferences({ state, getters }) {
      const loaded = Cookies.getJSON(getters.userPreferenceKey) || {}
      state.userPreferences = Object.assign(state.userPreferences, loaded)
    },
    saveUserPreferences({ state, getters }) {
      Cookies.set(getters.userPreferenceKey, state.userPreferences, { expires: 3650 })
    }
  },
  modules: {
  }
})
