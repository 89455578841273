<template>
  <v-menu v-model="menu" content-class="copy-menu"
          transition="scale-transition" :close-on-click="false" :close-on-content-click="false"
          offset-y min-width="auto">
  <template v-slot:activator="{ on, attrs }">
    <v-btn text color="green lighten-1" v-bind="attrs" v-on="on">
      Copy
      <span v-if="dates.length > 0"> ({{ dates.length }})</span>
    </v-btn>
  </template>
  <div>
    <v-date-picker v-model="dates" class="entry-copy"
                   multiple no-title first-day-of-week="1" scrollable
                   :allowed-dates="allowedDates">
    </v-date-picker>
    <v-spacer></v-spacer>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">OK</v-btn>
    </div>
  </div>
  </v-menu>
</template>

<script>
import dayjs from 'dayjs'
import { extractDate, randomID } from '../../util'
import { entryExtraTimeFields } from '../../data'

export default {
  name: 'EntryCopy',
  props: {
    entry: {
      type: Object
    }
  },
  data() {
    return {
      menu: false,
      dates: []
    }
  },
  methods: {
    reset() {
      this.dates = []
    },
    async execute(entry) {
      const startDay = dayjs(entry.start).startOf('day')

      for (const copyTo of this.dates) {
        const tempEntry = JSON.parse(JSON.stringify(entry))
        const offset = dayjs(copyTo).subtract(startDay)
        const timeFields = ['start', 'end'].concat(entryExtraTimeFields[entry.type] || [])

        for (const timeField of timeFields) {
          const timestamp = entry[timeField]
          if (timestamp) {
            tempEntry[timeField] = dayjs(timestamp).add(offset).format('YYYY-MM-DD HH:mm:ss')
          }
        }

        tempEntry.uid = randomID()

        await this.$store.dispatch('addEntry', tempEntry)
      }
    },
    allowedDates(v) {
      // TODO: maybe we want to block any day that has an event on it?
      return v !== extractDate(this.entry.start)
    }
  }
}
</script>

<style lang="scss">
.copy-menu {
  background: #fff;
}

.entry-copy {
  .v-date-picker-table {
    height: unset;
  }
}
</style>
