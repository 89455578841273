<template>
<v-card>
  <v-card-title>
    <span class="headline">Settings</span>
  </v-card-title>
  <v-card-text>
    <v-container>
      <span class="text-h6 black--text">Password</span>
      <v-row dense>
        <v-col cols="5" sm="5">
          <v-text-field v-model="currentPasscode" type="password"
                        label="Current" class="passcode"
                        :error-messages="errorMessage">
          </v-text-field>
        </v-col>
        <v-col cols="5" sm="5">
          <v-text-field v-model="newPasscode" type="password"
                        label="New" class="passcode">
          </v-text-field>
        </v-col>
        <v-col cols="2" sm="2" class="d-flex justify-center align-center">
          <v-btn text :disabled="!newPasscode" @click="changePasscode">Save</v-btn>
        </v-col>
      </v-row>

      <span class="text-h6 black--text">Email</span>
      <v-row dense>
        <v-col cols="10">
          <v-text-field v-model="email" type="text" @keydown.enter="setEmail">
          </v-text-field>
        </v-col>
        <v-col cols="2" sm="2" class="d-flex justify-center align-center">
          <v-btn text :disabled="!validEmail" >Save</v-btn>
        </v-col>
      </v-row>

      <span class="text-h6 black--text">Preferences</span>
      <v-row dense>
        <v-col cols="6">
          <v-checkbox v-model="$store.state.userPreferences.autofillDayJob"
                      label="Auto-fill day job" @change="saveUserPreferences">
          </v-checkbox>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn text @click="$emit('close')">Close</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as api from '../../api'
import { hash } from '../../util'

export default {
  name: 'UserSettings',
  data() {
    return {
      currentPasscode: null,
      newPasscode: null,
      changingPasscode: false,
      errorMessage: '',
      settingEmail: false,
      email: null
    }
  },
  watch: {
    changingPasscode() {
      this.newPasscode = this.currentPasscode = null
    },
    currentPasscode() {
      if (this.currentPasscode) {
        this.errorMessage = ''
      }
    }
  },
  computed: {
    ...mapState(['user']),
    links() {
      const output = []
      if (this.user) {
        output.push('calendar')
        if (this.user.admin) {
          output.push('users', 'overview', 'shoots', 'staff ot', 'costs')
        } else if (this.user.role === 'Supervisor' && this.user.name !== 'Paul Mann') {
          output.push('costs')
        }
      }
      return output
    },
    showNav() {
      return this.$route.name !== 'login'
    },
    validEmail() {
      return this.isValidEmail(this.email)
    }
  },
  methods: {
    ...mapActions(['saveUserPreferences']),
    async changePasscode() {
      const query = {
        email: this.$store.state.user.email,
        passcode_hash: hash(this.currentPasscode)
      }
      const { correct } = await api.get('/user/login', query)
      if (correct) {
        await api.put('/user/passcode', {
          passcode_hash: hash(this.newPasscode)
        })
        this.changingPasscode = false
      } else {
        this.errorMessage = 'Wrong password'
        setTimeout(() => { this.errorMessage = '' }, 1000)
      }
      this.newPasscode = this.currentPasscode = null
    },
    async setEmail() {
      const { email } = this
      await api.put('/user/email', { email })
      this.$store.state.user.email = email
      if (this.$store.state.user.admin) {
        for (const user of this.$store.state.users) {
          if (user.id === this.$store.state.user.id) {
            user.email = email
            break
          }
        }
      }
      this.settingEmail = false
    },
    logout() {
      this.$store.commit('logout')
      this.$router.push({ name: 'login' })
    }
  },
  mounted() {
    this.email = this.$store.state.user?.email
  }
}
</script>

<style scoped>

</style>
