import { render, staticRenderFns } from "./Journeys.vue?vue&type=template&id=3a10654a&scoped=true&"
import script from "./Journeys.vue?vue&type=script&lang=js&"
export * from "./Journeys.vue?vue&type=script&lang=js&"
import style0 from "./Journeys.vue?vue&type=style&index=0&id=3a10654a&lang=scss&scoped=true&"
import style1 from "./Journeys.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a10654a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VRadio,VRadioGroup,VSimpleTable,VTextField})
