export const userOptions = {
  role: [
    'supervisor',
    'senior_technician',
    'technician',
    'trainee'
  ],
  status: ['employee', 'freelance']
}

export const entryOptions = {
  type: ['workshop', 'shoot', 'recce', 'travel_day', 'leave']
}

export const AdminLevel = {
  NONE: 0,
  ADMIN: 1,
  OFFICE: 3,
  FINANCE: 5,
  SUPER: 10
}

export const journeyOptions = {
  modes: ['driver', 'passenger', 'public_transport'],
  vehicles: ['ms_van', 'own_car_with_kit', 'own_car']
}

export const leaveOptions = {
  reason: ['sick', 'holiday', 'unpaid', 'personal_appointment']
}

export const workingHours = {
  start: { hour: 8, minute: 0 },
  end: { hour: 16, minute: 30 }
}

export const entryExtraTimeFields = {
  shoot: [
    'depart_home',
    'start_at_base',
    'call',
    'lunch',
    'wrap',
    'depart',
    'finish_at_base',
    'arrive_home'
  ]
}

export const entryIcons = {
  workshop: 'mdi-domain',
  shoot: 'mdi-movie',
  recce: 'mdi-crosshairs',
  travel_day: 'mdi-train-car',
  leave: 'mdi-clock-remove-outline'
}

export const workingColours = {
  working: 'teal lighten-1',
  off: 'red accent-2'
}

export const compensationPerMile = 0.45

export const vehicleIcons = {
  ms_van: 'mdi-van-utility',
  own_car_with_kit: 'mdi-car-wrench',
  own_car: 'mdi-car'
}

export const journeyModeIcons = {
  driver: 'mdi-steering',
  passenger: 'mdi-seat-passenger',
  public_transport: 'mdi-train-car'
}

export const shootAmendmentOptions = {
  rates: ['T1', 'T1.5', 'T2', 'T3']
}
