<template>
<div style="max-width: 600px">
  <v-card-text>
    <v-container v-if="entry.start" class="pa-0">
      <v-row dense>
        <v-col cols="10">
          <job-select v-model="jobID" :disabled="lunch" :exclude="['paid']"></job-select>
        </v-col>
        <v-col>
          <v-checkbox v-model="lunch" label="Lunch"></v-checkbox>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" sm="3">
          <date-picker v-model="startDate" label="From"></date-picker>
        </v-col>
        <v-col cols="6" sm="3">
          <time-picker v-model="startTime"></time-picker>
        </v-col>
        <v-col cols="6" sm="3">
          <date-picker v-model="endDate" label="To"></date-picker>
        </v-col>
        <v-col cols="6" sm="3">
          <time-picker v-model="endTime"></time-picker>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field v-model="entry.notes" label="Notes" placeholder="Any notes"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
  <v-card-actions>
    <v-btn v-if="!entry.notSaved" text color="error" @click="remove">Delete</v-btn>
    <entry-copy ref="copy" :entry="entry"></entry-copy>
    <span class="flex-grow-1 text-center grey--text">
      <span v-if="endBeforeStart">End needs to be after start</span>
      <span v-if="clashes">Times clash with other entries</span>
    </span>
    <v-btn text @click="$emit('close')">Cancel</v-btn>
    <v-btn text :disabled="saveDisabled" @click="save">
      Save
    </v-btn>
  </v-card-actions>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import JobSelect from '../ui/JobSelect'
import { createDateTimeProp } from '../../util'
import DatePicker from '../ui/DatePicker'
import TimePicker from '../ui/TimePicker'
import EntryCopy from './EntryCopy'
import { entryMixin } from '../../mixins/EntryMixin'

export default {
  name: 'WorkshopEntry',
  mixins: [entryMixin],
  components: { EntryCopy, TimePicker, DatePicker, JobSelect },
  props: {
    user: Object,
    initialEntry: Object
  },
  data() {
    return {
      entry: {},
      jobID: null,
      lunch: false,
      copyMenu: false,
      copyDates: []
    }
  },
  watch: {
    lunch(l) {
      if (l) {
        this.jobID = null
      }
    }
  },
  computed: {
    ...mapState(['jobs', 'userPreferences']),
    ...mapGetters(['jobForID', 'jobForDay', 'dayHasLunch']),
    jobsList() {
      return this.jobs.filter(job => job.status === 'in progress' || job.status === 'completed')
    },
    startDate: createDateTimeProp('this.entry.start', 'date'),
    startTime: createDateTimeProp('this.entry.start', 'time'),
    endDate: createDateTimeProp('this.entry.end', 'date'),
    endTime: createDateTimeProp('this.entry.end', 'time'),
    saveDisabled() {
      return (!this.jobID && !this.lunch) || this.endBeforeStart || this.clashes
    },
    endBeforeStart() {
      if (this.entry.start && this.entry.end) {
        const start = this.entry.start.replace('T', ' ')
        const end = this.entry.end.replace('T', ' ')
        return end.localeCompare(start) !== 1
      }
      return false
    },
    clashes() {
      return this.$store.getters.entryClashes(this.entry)
    }
  },
  methods: {
    ...mapMutations(['createEntry']),
    setEntry(entry) {
      this.entry = { ...entry }
      this.jobID = entry.job?.id || null

      if (!this.jobID && this.userPreferences.autofillDayJob) {
        this.jobID = this.jobForDay(this.entry.start)
      }

      const tms = new Date(this.entry.start)
      if (entry.notSaved) {
        this.lunch = (!this.dayHasLunch(this.entry.start) &&
          (tms.getHours() === 12 || (tms.getHours() === 13 && Math.floor(tms.getMinutes() / 30) === 0)))
      } else {
        this.lunch = this.jobID === null
      }

      this.$refs.copy.reset()
    },
    formatDate(date) {
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    async save() {
      const entry = { ...this.entry }
      if (this.jobID && !this.lunch) {
        entry.job = this.jobForID(this.jobID)
      }
      if (entry.as_role === this.user.role) {
        entry.as_role = null
      }

      const action = entry.notSaved ? 'addEntry' : 'updateEntry'
      const payload = { type: 'workshop', ...entry }
      await this.$store.dispatch(action, payload)

      await this.$refs.copy.execute(payload)

      this.processFlagged(payload)

      this.$emit('close')
    },
    async remove() {
      await this.$store.dispatch('removeEntry', this.entry)
      this.$emit('close')
    },
    copyToDays() {

    }
  },
  mounted() {
    if (this.initialEntry) {
      this.setEntry(this.initialEntry)
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 600px) {
  .v-input--reverse .v-input__slot {
    flex-direction: column;
  }

  .edit-entry {
    .v-text-field__details, .v-messages {
      display: none;
    }
  }

  .v-dialog {
    margin: 15px;
  }
}

.edit-entry {
  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 5px;
  }
  .v-select__selections {
    flex-wrap: unset;
  }
}
</style>
