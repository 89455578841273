import Vue from 'vue'
import VueRouter from 'vue-router'
import Calendar from './views/Calendar.vue'
import Login from './views/Login.vue'
import store from './store'

Vue.use(VueRouter)

const guards = {
  onlyAdmin(to, from, next) {
    if (store.state.user?.admin) {
      next()
    } else {
      next({ name: 'login', params: { nextPage: to.name } })
    }
  },
  onlyAdminOrSupervisor(to, from, next) {
    const { user } = store.state
    if (user?.admin || user?.role === 'Supervisor') {
      if (user.name !== 'Paul Mann') {
        next()
      }
    } else {
      next({ name: 'login', params: { nextPage: to.name } })
    }
  },
  needsSignedInUser(to, from, next) {
    if (store.state.user?.id) {
      next()
    } else {
      next({ name: 'login', params: { nextPage: to.name } })
    }
  },
  toCalendarIfLoggedIn(to, from, next) {
    if (store.state.user?.id) {
      next({ name: 'calendar' })
    } else {
      next()
    }
  }
}

const routes = [
  {
    path: '/calendar',
    alias: '/',
    name: 'calendar',
    component: Calendar,
    beforeEnter: guards.needsSignedInUser
  },
  {
    path: '/calendar/:userSlug',
    name: 'user-calendar',
    component: Calendar,
    beforeEnter: guards.onlyAdmin,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: guards.toCalendarIfLoggedIn
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ './views/Users.vue'),
    beforeEnter: guards.onlyAdmin
  },
  {
    path: '/overview/:startDate?',
    name: 'overview',
    component: () => import(/* webpackChunkName: "shoots" */ './views/Overview.vue'),
    beforeEnter: guards.onlyAdmin,
    props: true
  },
  {
    path: '/shoots',
    name: 'shoots',
    component: () => import(/* webpackChunkName: "shoots" */ './views/Shoots.vue'),
    beforeEnter: guards.onlyAdmin
  },
  {
    path: '/costs',
    name: 'costs',
    component: () => import(/* webpackChunkName: "costs" */ './views/Costs.vue'),
    beforeEnter: guards.onlyAdminOrSupervisor
  },
  {
    path: '/staff-payroll',
    name: 'staff-payroll',
    component: () => import(/* webpackChunkName: "staff-ot" */ './views/StaffPayroll.vue'),
    beforeEnter: guards.onlyAdmin
  },
  {
    path: '/freelance-payroll',
    name: 'freelance-payroll',
    component: () => import(/* webpackChunkName: "freelance-invoices" */ './views/FreelancePayroll.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
