<template>
  <v-simple-table dense class="journeys">
    <template v-slot:default>
      <tbody>
      <tr>
        <td>
          <v-radio-group v-model="outwardMode">
            <template v-slot:label>
              Outward
            </template>
            <v-radio v-for="mode in modes" :key="mode" :value="mode"></v-radio>
          </v-radio-group>
        </td>
        <td>
          <v-radio-group v-model="returnMode">
            <template v-slot:label>
              Return
            </template>
            <v-radio v-for="mode in modes" :key="mode" :value="mode" :label="mode | title"></v-radio>
          </v-radio-group>
        </td>
      </tr>
      <tr v-if="showVehicles">
        <td>
          <v-radio-group v-model="outwardVehicle">
            <v-radio v-for="vehicle in vehicles" :key="vehicle" :value="vehicle"
                     @click="outwardVehicleClick">
            </v-radio>
          </v-radio-group>
        </td>
        <td>
          <v-radio-group v-model="returnVehicle">
            <v-radio v-for="vehicle in vehicles" :key="vehicle" :value="vehicle" :label="vehicle | title | titleMS"
                     @click="returnVehicleClick">
            </v-radio>
          </v-radio-group>
        </td>
      </tr>
      <tr v-if="showVehicles">
        <td></td>
        <td>
          <v-text-field v-model="mileage" type="number" label="Total mileage"></v-text-field>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { journeyOptions } from '../../data'
import { setKeys } from '../../util'

export default {
  name: 'Journeys',
  data() {
    return {
      modes: journeyOptions.modes,
      vehicles: journeyOptions.vehicles,
      outwardMode: null,
      returnMode: null,
      outwardVehicle: null,
      returnVehicle: null,
      mileage: null,
      last: {
        outwardVehicle: null,
        returnVehicle: null
      }
    }
  },
  watch: {
    outwardMode(mode) {
      if (mode && this.returnMode === null) {
        this.returnMode = mode
      }
      if (mode === 'public-transport') {
        this.outwardVehicle = null
      }
    },
    returnMode(mode) {
      if (mode && this.outwardMode === null) {
        this.outwardMode = mode
      }
      if (mode === 'public-transport') {
        this.returnVehicle = null
      }
    },
    valid() {
      this.$emit('update')
    }
  },
  computed: {
    outwardInVehicle() {
      return this.outwardMode === 'driver' || this.outwardMode === 'passenger'
    },
    returnInVehicle() {
      return this.returnMode === 'driver' || this.returnMode === 'passenger'
    },
    showVehicles() {
      return this.outwardInVehicle || this.returnInVehicle
    },
    valid() {
      const base = this.outwardMode && this.returnMode
      const outwardVehicle = (this.outwardInVehicle && this.outwardVehicle) || !this.outwardInVehicle
      const returnVehicle = (this.returnInVehicle && this.returnVehicle) || !this.returnInVehicle
      return base && outwardVehicle && returnVehicle
    }
  },
  methods: {
    setValues(entry) {
      const props = ['outward_mode', 'return_mode', 'outward_vehicle', 'return_vehicle', 'mileage']
      setKeys(entry, this.$data, props)
      this.last.outwardVehicle = this.last.returnVehicle = null
    },
    outwardVehicleClick() {
      if (this.outwardVehicle === this.last.outwardVehicle) {
        this.outwardVehicle = null
      }
      this.last.outwardVehicle = this.outwardVehicle
    },
    returnVehicleClick() {
      if (this.returnVehicle === this.last.returnVehicle) {
        this.returnVehicle = null
      }
      this.last.returnVehicle = this.returnVehicle
    }
  }
}
</script>

<style lang="scss" scoped>
th.slim {
  padding: 0 !important;
  text-align: center !important;
}

</style>

<style lang="scss">
.journeys {
  table {
    tr {
      &:hover {
        background: unset !important;
      }
    }
    .v-radio {
      justify-content: center;
      margin-bottom: 2px !important;
    }
    .v-input--selection-controls {
      margin-top: 6px !important;
    }
    .v-input--radio-group--column {
      margin-bottom: 8px !important;
      @media (min-width: 700px) {
        margin-bottom: 0 !important;
      }
    }
    .v-messages {
      min-height: 9px !important;
    }
    .v-input--selection-controls__ripple {
      width: 14px;
      height: 14px;
      top: -2px;
      left: -2px;
    }
  }
}
</style>
