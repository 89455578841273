import { Base64 } from 'js-base64'
import store from './store'

const server = process.env.NODE_ENV === 'development'
  ? 'http://flossy.local:8001'
  : `https://${process.env.VUE_APP_STAGE === 'dev' ? 'dev.' : ''}api.timesheets.machineshop.co.uk`

export function createURL(endpoint, params) {
  let query = ''
  if (params) {
    const usp = new URLSearchParams()
    for (const [key, value] of Object.entries(params)) {
      const values = Array.isArray(value) ? value : [value]
      values.forEach((v) => {
        usp.append(key, v)
      })
    }
    query = usp.toString()
  }

  if (endpoint[0] !== '/') {
    endpoint = `/${endpoint}`
  }

  return `${server}${endpoint}?${query}`
}

async function fetchEndpoint(method, endpoint, params) {
  const url = createURL(endpoint, params)
  const headers = new Headers()
  const auth = Base64.encode(store.getters.credentials.join(':'))
  headers.set('Authorization', `Basic ${auth}`)

  const response = await fetch(url, { method, headers })
  return await response.json()
}

export function get(endpoint, params) {
  return fetchEndpoint('GET', endpoint, params)
}

export function post(endpoint, params) {
  return fetchEndpoint('POST', endpoint, params)
}

export function put(endpoint, params) {
  return fetchEndpoint('PUT', endpoint, params)
}

export function del(endpoint, params) {
  return fetchEndpoint('DELETE', endpoint, params)
}
