<template>
  <div style="max-width: 600px; min-width: 300px">
    <v-card-text>
      <v-container v-if="entry.start" class="pa-0">
        <v-row dense>
          <v-col>
            <v-select v-model="reason" :items="reasonOptions"
                      label="Reason">
            </v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" sm="3">
            <v-checkbox v-model="allDay" label="All day" @change="allDayChanged"></v-checkbox>
          </v-col>
          <v-col cols="6" sm="3">
            <date-picker v-model="startDate" label="On"></date-picker>
          </v-col>
          <v-col cols="6" sm="3">
            <time-picker v-model="startTime" label="From" :disabled="allDay"></time-picker>
          </v-col>
          <v-col cols="6" sm="3">
            <time-picker v-model="endTime" label="To" :disabled="allDay"></time-picker>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field v-model="entry.notes" label="Notes" placeholder="Any notes"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="!entry.notSaved" text color="error" @click="remove">Delete</v-btn>
      <entry-copy ref="copy" :entry="entry"></entry-copy>
      <span class="flex-grow-1 text-center grey--text">
      <span v-if="endBeforeStart">End needs to be after start</span>
      <span v-if="clashes">Times clash with other entries</span>
    </span>
      <v-btn text @click="$emit('close')">Cancel</v-btn>
      <v-btn text :disabled="saveDisabled" @click="save">
        Save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import dayjs from 'dayjs'
import { createDateTimeProp } from '../../util'
import DatePicker from '../ui/DatePicker'
import TimePicker from '../ui/TimePicker'
import { leaveOptions, workingHours } from '../../data'
import EntryCopy from './EntryCopy'
import { entryMixin } from '../../mixins/EntryMixin'

export default {
  name: 'LeaveEntry',
  mixins: [entryMixin],
  components: { EntryCopy, DatePicker, TimePicker },
  props: {
    user: Object,
    initialEntry: Object
  },
  data() {
    return {
      entry: {},
      allDay: false,
      reason: null,
      temp: {
        start: null,
        end: null
      }
    }
  },
  computed: {
    reasonOptions() {
      return leaveOptions.reason.map((reason) => {
        return {
          text: this.makeTitle(reason),
          value: reason
        }
      })
    },
    startDate: createDateTimeProp('this.entry.start', 'date'),
    startTime: createDateTimeProp('this.entry.start', 'time'),
    endDate: createDateTimeProp('this.entry.end', 'date'),
    endTime: createDateTimeProp('this.entry.end', 'time'),
    saveDisabled() {
      return !this.reason || this.endBeforeStart || this.clashes
    },
    endBeforeStart() {
      if (this.entry.start && this.entry.end) {
        return this.entry.end.localeCompare(this.entry.start) !== 1
      }
      return false
    },
    clashes() {
      return this.$store.getters.entryClashes(this.entry)
    }
  },
  methods: {
    ...mapMutations(['createEntry']),
    setEntry(entry) {
      this.entry = { ...entry }
      this.allDay = entry.all_day || false
      this.reason = entry.reason
      this.$refs.copy.reset()
    },
    async save() {
      const entry = { ...this.entry }
      entry.all_day = this.allDay
      entry.reason = this.reason

      const action = entry.notSaved ? 'addEntry' : 'updateEntry'
      const payload = { type: 'leave', ...entry }
      await this.$store.dispatch(action, payload)
      await this.$refs.copy.execute(payload)

      this.processFlagged(payload)

      this.$emit('close')
    },
    async remove() {
      await this.$store.dispatch('removeEntry', this.entry)
      this.$emit('close')
    },
    allDayChanged() {
      if (this.allDay) {
        for (const side of ['start', 'end']) {
          this.temp[side] = this.entry[side]
          const d = dayjs(this.entry[side]).startOf('day')
          this.entry[side] = d
            .hour(workingHours[side].hour)
            .minute(workingHours[side].minute)
            .format('YYYY-MM-DD HH:mm:ss')
        }
      } else {
        for (const side of ['start', 'end']) {
          this.entry[side] = this.temp[side]
        }
      }
    }
  },
  mounted() {
    if (this.initialEntry) {
      this.setEntry(this.initialEntry)
    }
  }
}
</script>

<style scoped>

</style>
