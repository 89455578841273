import sha1 from 'crypto-js/sha1'
import camelCase from 'camelcase'
import dayjs from 'dayjs'

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export function randomID(length = 16) {
  return Array(length)
    .fill(0)
    .map(_ => characters[Math.floor(Math.random() * characters.length)])
    .join('')
}

export function makeTitle(str) {
  if (!str) {
    return ''
  }
  if (str.length <= 1) {
    return str
  }
  str = str.replace(/_|-/g, ' ')
  return str[0].toUpperCase() + str.slice(1).split(/(?=[A-Z])/).join(' ').toLowerCase()
}

export function makeFullTitle(str) {
  return makeTitle(str).split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
}

export function msToDatetime(ms) {
  const date = new Date(ms)
  date.setTime(ms + date.getTimezoneOffset() * -60 * 1000)
  return date.toISOString()
    .replace('T', ' ')
    .substr(0, 19)
}

export function datetimeToMs(dt) {
  const date = new Date(dt)
  return date.getTime()
}

export function hash(passcode) {
  return sha1(passcode).toString()
}

export function grabKeys(o, keys, initial) {
  const output = initial || {}
  keys.forEach((k) => { output[k] = o[k] })
  return output
}

export function extractDateTime(ts) {
  return (ts && ts.split(/[ T]/)) || [null, null]
}

export function extractDate(ts) {
  return extractDateTime(ts)[0]
}

export function extractTime(ts) {
  return extractDateTime(ts)[1]
}

export function timeAddMinutes(time, addedMinutes) {
  let [hours, minutes] = time.split(':')
  minutes = +minutes + hours * 60
  minutes += addedMinutes
  hours = Math.floor(minutes / 60)
  minutes -= hours * 60
  return `${hours}`.padStart(2, '0') + ':' + `${minutes}`.padStart(2, '0')
}

export function createDateTime(date, time) {
  return `${date} ${time}`
}

export function salaryToHourly(salary) {
  return Math.round(salary / 52 / 5 / 8 * 100) / 100
}

export function hourlyToSalary(hourly) {
  return Math.round(hourly * 8 * 5 * 52 * 100) / 100
}

export function dayString(date) {
  const options = { weekday: 'long', day: 'numeric', month: 'long' }
  return (new Date(date)).toLocaleString('en-gb', options).replace(',', '')
}

export function createDateTimeProp(prop, field) {
  return {
    get() {
      const value = Function(`return ${prop}`).bind(this)()
      if (!value) {
        return null
      }
      const [date, time] = extractDateTime(value)
      if (field === 'date') {
        return date
      } else if (field === 'time') {
        return time.slice(0, 5)
      }
    },
    set(v) {
      const current = Function(`return ${prop}`).bind(this)()
      if (!current) {
        console.log(field, prop, 'not current', current)
        return
      }
      const [date, time] = extractDateTime(current)
      let next
      if (field === 'date') {
        next = createDateTime(v, time)
      } else if (field === 'time') {
        next = createDateTime(date, v)
      }
      Function('value', `${prop} = value`).bind(this)(next)
    }
  }
}

export function minValue(...values) {
  values = values.filter(v => v !== null)
  if (values.length === 0) {
    return values
  }
  let min = values[0]
  values.forEach((v) => {
    if (v < min) {
      min = v
    }
  })
  return min
}

export function maxValue(...values) {
  values = values.filter(v => v !== null)
  if (values.length === 0) {
    return values
  }
  let max = values[0]
  values.forEach((v) => {
    if (v && v > max) {
      max = v
    }
  })
  return max
}

export function setKeys(source, target, snake_keys) {
  for (const key of snake_keys) {
    target[camelCase(key)] = source[key] || null
  }
}

export function today() {
  return dayjs().format('YYYY-MM-DD')
}
