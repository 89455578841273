import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { makeTitle, makeFullTitle, extractDate } from './util'
import { entryIcons, vehicleIcons, journeyModeIcons, shootAmendmentOptions } from './data'

Vue.config.productionTip = false

Vue.mixin({
  data() {
    return {
      entryIcons,
      vehicleIcons,
      journeyModeIcons,
      shootAmendmentOptions
    }
  },
  methods: {
    doubleDigit: (n) => n.toLocaleString('en-gb', { minimumIntegerDigits: 2 }),
    slugify: s => s.replace(' ', '-').toLowerCase(),
    isValidEmail: (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    makeTitle,
    makeFullTitle,
    extractDate
  }
})

Vue.filter('title', (str) => makeTitle(str))
Vue.filter('fullTitle', (str) => makeFullTitle(str))
Vue.filter('titleMS', (str) => str.replace(/ms/i, 'MS'))
Vue.filter('noUnderscore', (str) => str.replace(/_/i, ' '))
Vue.filter('maxLength', (str, length = 10) => {
  if (str.length <= length) {
    return str
  }
  return str.substr(0, length) + '...'
})
Vue.filter('hrs', (hours) => `${hours}${+hours === 1 ? 'hr' : 'hrs'}`)
Vue.filter('prettyDate', (date) => date.split('-').reverse().join('.'))

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
