import * as api from '../api'

export const entryMixin = {
  props: {
    flagged: Boolean,
    flaggedChanged: Boolean
  },
  methods: {
    processFlagged(entry) {
      if (this.flaggedChanged) {
        entry.flagged = this.flagged
        this.$store.commit('updateEntry', entry)
        const { uid, type, flagged } = entry
        return api.put('/entry/flag', { uid, entry_type: type, flagged })
      }
    }
  }
}
